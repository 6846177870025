import { Drawer, Stack, Typography, Tooltip, IconButton } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const ComparisonDrawer = ({ open, onClose, children }: any) => {
  return (
    <Drawer
      open={open}
      anchor='bottom'
      onClose={onClose}
      PaperProps={{
        style: {
          height: '98vh',
          width: '100vw',
          backgroundColor: '#fff',
          maxWidth: 'none',
          maxHeight: 'none',
          borderRadius: '8px 8px 0px 0px'
        }
      }}>
      <Stack
        direction='row'
        sx={{
          bgcolor: 'primary.600',
          alignSelf: 'stretch',
          p: '12px 24px',
          borderRadius: '8px 8px 0px 0px'
        }}>
        <Stack spacing={1} sx={{ flexGrow: 1, textAlign: 'center', alignSelf: 'center' }}>
          <Typography variant='h3' fontWeight='bold' color='white.main'>
            Enhanced Comparison (Limit: 2 Docs)
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton
            aria-label='delete'
            onClick={onClose}
            size='small'
            sx={{ position: 'absolute', display: 'flex', left: '97%', p: 0 }}>
            <HighlightOffOutlinedIcon
              fontSize='inherit'
              sx={{ height: '24px', width: '24px', color: 'white.main' }}
            />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack>{children}</Stack>
    </Drawer>
  );
};

export default ComparisonDrawer;
