import React, { useCallback, useContext, useMemo, useEffect, useState } from 'react';

// MUI
import {
  Stack,
  Box,
  Tooltip,
  Checkbox,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Divider
} from '@mui/material';

import { debounce } from 'lodash';
import styles from '../styles/Details.styles';
// Custom Components
import VivproDatagrid from '../../../components/Datagrid';

// store
import store from '../../../store/GuidanceDocuments';
import GlobalStore from '../../../store';
import GlobalActions from '../../../store/actions';

import PDFCellComponent from './PDFCellRenderer';
import { getFileName } from '../../../helpers/getFileName';

// constants
import { GUIDANCE_COLUMNS } from '../const';
import getTableColumnWithCellRenderers from '../utils/getTableColumnWithCellRenderers';
import actions from '../../../store/GuidanceDocuments/actions';
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import PDFPreview from '../../../components/PdfPreview/PDFPreview';
import getDatagridFilterOption from '../../../utils/getDatagridFilterOption';
import { DATAGRID_OPTION_LIST } from '../../../components/Datagrid/constants';
import { MagnifyingGlassIcon } from '../../../assets/svgs/Icons';

const GuidanceDetails = ({
  pagination,
  setPagination,
  sort,
  setSort,
  sortFields,
  getGuidanceData,
  isFilterUpdated,
  setIsFilterUpdated,
  getDatawithDataGridFilter,
  dataGridFilter,
  isComparison = false
}: any) => {
  const [datagridMessage] = useState('Loading...');
  const { dispatch } = useContext(GlobalStore) as any;
  const [pdfUrl, setPdfUrl] = useState('');
  const [selectedRowData, setSelectdRowData] = useState({}) as any;
  const { guidanceState, guidanceDispatch } = useContext(store) as any;
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (isFilterUpdated) {
      setIsFilterUpdated(false);
    }
  }, [isFilterUpdated]);

  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const filename = {
    prefix: 'ria',
    module: 'guidances',
    postfix: date,
    additional: '',
    extension: 'csv'
  };

  const fetchData = useCallback(
    (pageModel: any, sortModel: any, gridFilters: any, query: string = '') => {
      const allFilters = {
        ...guidanceState.availableFilters,
        ...(query?.length > 0 ? { filter_keywords: [query] } : {})
      };

      if (query?.length < 1) {
        delete allFilters.filter_keywords;
      }

      getGuidanceData(
        allFilters,
        pageModel.pageSize,
        pageModel.page * pageModel.pageSize,
        sortModel,
        false,
        gridFilters
      );
    },
    [guidanceState.availableFilters]
  );

  const handlePageChange = useCallback(
    (pageModel: any) => {
      if (!isFilterUpdated) {
        fetchData(pageModel, sortFields, dataGridFilter);
        setPagination(pageModel);
      }
    },
    [sortFields, isFilterUpdated, dataGridFilter]
  );

  const handleSortModelChange = useCallback(
    (newSortingModel: any) => {
      const modifiedList: any = [];

      newSortingModel.forEach((item: any) => {
        modifiedList.push(item);
      });

      if (JSON.stringify(modifiedList) !== JSON.stringify(guidanceState.sortFields)) {
        fetchData(pagination, modifiedList, dataGridFilter);
      }
      guidanceDispatch({
        type: actions.SET_SORT,
        value: modifiedList
      });

      setSort(modifiedList);
    },
    [pagination, guidanceState.sortFields, dataGridFilter]
  );

  const handlePDFOpenClick = useCallback((pdfLink: any, rowData: any) => {
    setPdfUrl(pdfLink);
    setSelectdRowData(rowData);
  }, []);

  const handleComparisonCheckbox = useCallback(
    async (id: string) => {
      // eslint-disable-next-line no-underscore-dangle
      if (guidanceState.documentsToCompare.find((doc: any) => doc._id === id)) {
        guidanceDispatch({
          type: actions.REMOVE_DOCUMENT_TO_COMPARE,
          // eslint-disable-next-line no-underscore-dangle
          value: id
        });
      } else {
        guidanceDispatch({
          type: actions.SET_DOCUMENT_TO_COMPARE,
          // eslint-disable-next-line no-underscore-dangle
          value: guidanceState.tableData.find((doc: any) => doc._id === id)
        });
      }
    },
    [guidanceState.tableData, guidanceState.documentsToCompare]
  );

  const pdfCellRenderer = useCallback(
    ({ value, rowData }: any) => (
      <PDFCellComponent value={value} rowData={rowData} onPDFButtonClick={handlePDFOpenClick} />
    ),
    [handlePDFOpenClick]
  );

  const tableColumns = useMemo(
    () =>
      getTableColumnWithCellRenderers(GUIDANCE_COLUMNS, [
        { field: 'pdf_s3_url', cellRenderer: pdfCellRenderer }
      ]),
    [pdfCellRenderer, guidanceState.tableData]
  );

  const handleChatRia = ({
    // eslint-disable-next-line no-shadow
    pdfUrl = ''
  }: {
    resultDetails?: any;
    pdfUrl?: string;
  }) => {
    const mappedSourceName = getDocumentSourceName('Guidances');
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: mappedSourceName
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: {
          field_regulated_product_field: selectedRowData?.regulated_product_field ?? '',
          field_docket_number: selectedRowData?.docket_number ?? '',
          center: selectedRowData?.center ?? '',
          field_issue_datetime: selectedRowData?.issue_datetime ?? '',
          title: selectedRowData?.title ?? ''
        },
        source: mappedSourceName,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
    setPdfUrl('');
  };

  const renderableTableColumns = useMemo(() => {
    const renderableColumns = [...tableColumns];
    if (isComparison) {
      renderableColumns.unshift({
        field: '_id',
        headerName: 'Select',
        width: 100,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
        disableExport: true,
        renderCell: ({ value }: any) => (
          <Tooltip
            title={
              guidanceState.documentsToCompare.length === 2 &&
              // eslint-disable-next-line no-underscore-dangle
              !guidanceState.documentsToCompare.find((doc: any) => doc._id === value)
                ? 'Selection limit reached'
                : ''
            }>
            <Box key={value}>
              <Checkbox
                disabled={
                  guidanceState.documentsToCompare.length === 2 &&
                  // eslint-disable-next-line no-underscore-dangle
                  !guidanceState.documentsToCompare.find((doc: any) => doc._id === value)
                }
                // eslint-disable-next-line no-underscore-dangle
                checked={!!guidanceState.documentsToCompare.find((doc: any) => doc._id === value)}
                onClick={() => handleComparisonCheckbox(value)}
              />
            </Box>
          </Tooltip>
        )
      });
    }
    return getDatagridFilterOption(renderableColumns, DATAGRID_OPTION_LIST, guidanceState);
  }, [
    guidanceState.documentsToCompare,
    tableColumns,
    guidanceState.compareEnabled,
    guidanceState.tableData,
    guidanceState.dataGridFilters
  ]);

  const handleFilterChange = useCallback(
    debounce(newFilterModel => {
      if (newFilterModel?.items?.length > 0) {
        getDatawithDataGridFilter(
          guidanceState.availableFilters,
          newFilterModel,
          sortFields,
          pagination.pageSize,
          pagination.page * pagination.pageSize
        );
      } else {
        fetchData(pagination, sortFields, {});
      }
    }, 300),
    [guidanceState.availableFilters, sortFields]
  );

  useEffect(() => {
    return () => {
      handleFilterChange.cancel();
    };
  }, [handleFilterChange]);

  const updateSearch = useCallback(
    (query: string) => {
      fetchData(pagination, sortFields, dataGridFilter, query);
    },
    [pagination, sortFields, dataGridFilter]
  );

  const debouncedChangeHandler = useMemo(() => debounce(updateSearch, 200), [updateSearch]);

  const handleInputTextChange = (event: any) => {
    setSearchQuery(event.target.value);
    debouncedChangeHandler(event.target.value);
  };

  return (
    <Stack
      sx={{ ...styles.root, ...(isComparison && { height: 'calc(100vh - 176px)', pb: 0, mt: 0 }) }}>
      {isComparison && (
        <Box mt={4}>
          <Stack direction='row' justifyContent='space-between' px='28px' height='30px'>
            <TextField
              sx={{
                '& .MuiInputBase-root': { borderRadius: '32px', height: '30px', width: '300px' }
              }}
              placeholder='Find Guidances'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <MagnifyingGlassIcon sx={{ height: '14px', width: '14px' }} />
                  </InputAdornment>
                )
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: 75
              }}
              onChange={handleInputTextChange}
              value={searchQuery}
            />
            <Stack direction='row' spacing={2} alignItems='center'>
              <Box>
                <Typography>{guidanceState.documentsToCompare.length} selected</Typography>
              </Box>
              <Button
                sx={{
                  height: '30px',
                  padding: '15px 24px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '16px',
                  backgroundColor: 'primary.650',
                  color: 'white.main',
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: 700,
                  '&:hover': {
                    backgroundColor: 'white.main',
                    border: 1,
                    borderColor: 'primary.main',
                    borderStyle: 'solid',
                    color: 'primary.600'
                  },
                  '&:disabled': {
                    backgroundColor: 'gray.550',
                    color: 'gray.background'
                  }
                }}>
                Compare
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ my: '16px', mx: '28px' }} />
        </Box>
      )}
      <Box>
        <PDFPreview
          open={Boolean(pdfUrl)}
          pdfUrl={pdfUrl}
          onClose={() => setPdfUrl('')}
          handleChatRia={handleChatRia}
        />
      </Box>
      <VivproDatagrid
        rows={guidanceState.tableData}
        columnsMapping={renderableTableColumns}
        rowId='_id'
        csvFileName={getFileName(filename)}
        noRowMessage={datagridMessage}
        loading={guidanceState.loading}
        rowCount={guidanceState.totalRecord}
        isPagination
        pagination={pagination}
        setPagination={handlePageChange}
        sortingModel={sort}
        handleSortModelChange={handleSortModelChange}
        isServerSideExport
        isFilterModeServer
        allowFilterPanelWithLogicOperators
        handleFilterModelChange={handleFilterChange}
      />
    </Stack>
  );
};

export default React.memo(GuidanceDetails);
