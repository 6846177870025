import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
  Divider,
  Stack,
  Box,
  Typography,
  Tooltip,
  Popover,
  IconButton,
  Chip,
  Grid,
  FormControl,
  InputAdornment,
  LinearProgress,
  Button,
  OutlinedInput,
  Slide,
  Badge
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import axios, { CancelTokenSource } from 'axios';
import { isArray, isEmpty, uniqueId } from 'lodash';
import { v4 } from 'uuid';
import SendIcon from '../../Images/svgs/sendIcon';
import BubbleIcon from '../../assets/svgs/ChatRIA/BubbleIcon';
import comparisonClasses from './styles/LabelSelection.style';
import useDetectNonChromeBrowser from '../../hooks/useDetectNonChromeBrowser';
import {
  DownloadIcon,
  InfoIconSummary,
  SwapIcon,
  AriaResponseFollowUp
} from '../../assets/svgs/Icons';
import {
  LABEL_COLOR,
  sourceFlagMapping,
  sourceFlagMappingTooltip,
  LABEL_TOOLTIP_TITLE
} from '../../pages/SearchResults/constants';
import draftableComparisonViewStyles from './styles/DraftableComparisonView.styles';
import comparisonDialogStyles from './styles/ComparisonDialog.styles';
import ResultsStore from '../../store/SearchResults';
import Card from '../../pages/SearchResults/components/Card';
import { sendComparisonQuery } from '../../api/pages/Reg360';
import ChatBubbles from '../ChatRIA/component/ChatBubbles';
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';
import { getReportTitle } from '../../api/modules/ChatRIA';
import styles from './styles/DraftableComparsionChatRia.styles';

interface DraftableComparisonViewPropsType {
  viewerURL: string;
  parentApplicationNumber: string;
  childApplicationNumber: string;
  parentApplicationName?: string;
  childApplicationName?: string;
  downloadFile: () => void;
  showFooter?: boolean;
  parentApplicationSource?: string;
  childApplicationSource?: string;
  handleSwapLabels?: () => void;
  isSwitchOptionAvailable?: boolean;
  parentApplicationLabels?: any[];
  childApplicationLabels?: any[];
  fullWidth?: boolean;
  parentPdfLink?: string;
  childPdfLink?: string;
}

const DraftableComparisonView: React.FC<DraftableComparisonViewPropsType> = ({
  viewerURL,
  downloadFile,
  parentApplicationNumber,
  childApplicationNumber,
  parentApplicationName = '',
  childApplicationName = '',
  showFooter = true,
  parentApplicationSource = '',
  childApplicationSource = '',
  handleSwapLabels = undefined,
  isSwitchOptionAvailable = false,
  parentApplicationLabels = [],
  childApplicationLabels = [],
  fullWidth = true,
  parentPdfLink = '',
  childPdfLink = ''
}) => {
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const { state, dispatch } = useContext(GlobalStore) as any;
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showFollowUpChatRia, setShowFollowUpChatRia] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);
  const [followUpQuery, setFollowUpQuery] = useState('');
  const [chatFlow, setChatFlow] = useState<any>([]);
  const [latestChatId, setLatestChatId] = useState<{ chatId: string; isNew: boolean }>({
    chatId: '',
    isNew: true
  });
  const [reportLoading, setReportLoading] = React.useState<boolean>(false);
  const [addedToReportList, setAddedToReportList] = React.useState<string[]>([]);

  const { detectBrowserAndDisplayDisclaimer } = useDetectNonChromeBrowser();

  useEffect(() => {
    detectBrowserAndDisplayDisclaimer();
  }, []);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [comparisonApplicationsIndex, setComparisonApplicationsIndex] = useState<number>(0);

  const open = Boolean(anchorEl);

  const ParentFlagComponent = sourceFlagMapping[parentApplicationSource];
  const parentFlagTooltipTitle = sourceFlagMappingTooltip[parentApplicationSource];
  const ChildFlagComponent = sourceFlagMapping[childApplicationSource];
  const childFlagTooltipTitle = sourceFlagMappingTooltip[childApplicationSource];

  const reportCount = state?.reportLayout?.sections?.length;

  const { resultsState } = useContext(ResultsStore);

  const handlePopoverOpen = (event: any, name: string) => {
    setAnchorEl(event.currentTarget);

    const index = resultsState?.comparisonApplications?.findIndex(
      (item: any) => item?.title === name
    );

    if (index < 0) return;

    setComparisonApplicationsIndex(index);
  };

  const getApplicationChipTag = (labels: any[]) => {
    return (
      labels &&
      labels.map((label: string) => {
        const labelKey = label.toLowerCase();
        const labelColor = LABEL_COLOR[labelKey];
        const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
        return labelColor ? (
          <Tooltip
            title={labelTooltipTitle}
            disableInteractive
            disableFocusListener
            disableTouchListener>
            <Chip
              label={label}
              key={label}
              sx={{ ...draftableComparisonViewStyles.applicationChip, bgcolor: labelColor }}
            />
          </Tooltip>
        ) : null;
      })
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const messageFormatter = (message: string[]) => {
    const newMessage = message.join('').trimStart();
    let messageError = false;
    if (newMessage.startsWith('.')) {
      return newMessage.substring(1);
    }
    messageError = newMessage.toLowerCase().startsWith('either label for');
    return { newMessage, messageError };
  };

  const handleFollowUpChatRiaWindow = () => {
    if (showFollowUpChatRia) {
      setShowFollowUpChatRia(false);
      return;
    }

    setShowFollowUpChatRia(true);
  };

  const handleGetComparisonQueryOutput = async () => {
    try {
      setResultLoading(true);
      // Cancel the previous request if it exists
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }

      // Create a new cancel token source
      cancelTokenSourceRef.current = axios.CancelToken.source();

      const comparisonPayload = {
        question: followUpQuery,
        parent_pdf_details: {
          url: parentPdfLink
        },
        child_pdf_details: {
          url: childPdfLink
        }
      };
      const chatId = v4();
      setLatestChatId({ chatId, isNew: true });
      await sendComparisonQuery({
        payload: comparisonPayload,
        cancelToken: cancelTokenSourceRef.current.token,
        onDownloadProgress: async (progressEvent: any) => {
          const { currentTarget } = progressEvent?.event ?? progressEvent;
          if (!currentTarget) return;
          const data = currentTarget.response;
          const lines = data.trim().split('\n');

          const newMessages = lines.map(
            (line: string) => JSON.parse(line.replace('data: ', '')).message
          );

          const responseMessage = newMessages.filter(
            (message: string) =>
              typeof message === 'string' &&
              !message?.startsWith('top_section') &&
              !message?.startsWith('other_sections')
          );

          const { newMessage, messageError }: any = messageFormatter(responseMessage);

          const answer = {
            text: newMessage,
            type: 'bot',
            error: messageError,
            id: chatId
          };

          setChatFlow((prev: any) => {
            if (isEmpty(prev)) {
              return [[answer]];
            }

            const previousAnswers = [...prev];
            let lastAnswer = previousAnswers[previousAnswers.length - 1];

            if (isArray(lastAnswer)) {
              lastAnswer = [answer];
              previousAnswers.pop();
              previousAnswers.push(lastAnswer);
            } else {
              if (lastAnswer?.type === 'bot') {
                previousAnswers.pop();
              }
              previousAnswers.push([answer]);
            }

            return previousAnswers;
          });

          setResultLoading(false);

          if (newMessage) {
            setFollowUpQuery('');
          }
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setResultLoading(false);
    }
  };

  const handleScrollToBottom = useCallback(() => {
    const lastMessage = document.getElementById(`chat-bubble-${latestChatId.chatId}`);
    if (scrollRef.current && lastMessage) {
      if (latestChatId.isNew) {
        scrollRef.current.scrollTo({
          top: lastMessage.clientHeight + lastMessage.offsetTop,
          behavior: 'smooth'
        });
      } else {
        scrollRef.current.scrollTo({
          top: lastMessage.offsetTop - 200,
          behavior: 'smooth'
        });
      }
    }
  }, [latestChatId]);

  useEffect(() => {
    handleScrollToBottom();
  }, [chatFlow]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const chatIds = state.reportLayout.sections.map((section: any) => section._chatId);
    setAddedToReportList(chatIds);
  }, [state.reportLayout]);

  const handleCancelCall = () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
    }
  };

  const getTitle = async (content: string, id: any, question: string): Promise<string> => {
    const payload = {
      [id]: {
        answer: content,
        question
      }
    };
    const response = await getReportTitle(payload);
    if (response?.status === 200) {
      const { data } = response;
      if (data?.body) {
        return data?.body[0].title;
      }
    }
    return '';
  };

  const handleReportClick = () => {
    window.open('/reports', '_blank');
  };

  const handleAddToReportAction = useCallback(
    async (
      content: string,
      question: string,
      action: string,
      id?: string,
      bubbleIndex?: number
    ) => {
      setReportLoading(true);
      const reportTitle: string = await getTitle(content, id, question);

      if (action === 'add') {
        const random = Math.floor(Math.random() * 1000);
        dispatch({
          type: GlobalActions.ADD_TEMPLATE_TO_REPORT,
          value: {
            dataSource: 'custom',
            sectionType: 'TEXT',
            textType: 'HTML_TEXT',
            id: uniqueId(`chatria__${random}_`),
            _chatId: id,
            style: {
              placement: {
                h: 10,
                w: 12
              },
              references: {
                show: true,
                text: `Comparsion / ${parentApplicationNumber} / ${childApplicationNumber} `
              }
            },
            content: `<h3>${reportTitle}</h3>\n\n${content}`
          }
        });
      } else if (action === 'remove') {
        dispatch({
          type: GlobalActions.REMOVE_CHAT_RIA_REPORT,
          value: id
        });
      }
      setChatFlow((prev: any) => {
        const newChatFlow = Array.isArray(prev) ? [...prev] : [];
        if (bubbleIndex) {
          const selectedBubble = newChatFlow[bubbleIndex];
          const indexOfAnswer = selectedBubble.findIndex((item: any) => item.id === id);
          selectedBubble[indexOfAnswer].inReport = action === 'add';
        }
        return newChatFlow;
      });
      setReportLoading(false);
    },
    [chatFlow]
  );

  return (
    <Stack>
      {showFooter && (
        <Stack
          direction='row'
          spacing={2}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={draftableComparisonViewStyles.ctaSeparator}
            />
          }
          sx={draftableComparisonViewStyles.secondaryHeaderWrapper}
          justifyContent='flex-end'>
          <Stack
            direction='row'
            spacing={0.75}
            onClick={downloadFile}
            sx={draftableComparisonViewStyles.ctaWrapper}>
            <DownloadIcon sx={draftableComparisonViewStyles.downloadIcon} />
            <Typography sx={draftableComparisonViewStyles.downloadComparisonText}>
              Download Comparison
            </Typography>
          </Stack>
          <Stack
            direction='row'
            spacing={0.75}
            sx={draftableComparisonViewStyles.ctaWrapper}
            onClick={handleFollowUpChatRiaWindow}>
            <AriaResponseFollowUp sx={draftableComparisonViewStyles.downloadIcon} />
            <Typography sx={draftableComparisonViewStyles.downloadComparisonText}>
              Ask A Follow-Up (With Chat RIA)
            </Typography>
          </Stack>
        </Stack>
      )}
      {resultsState?.comparisonApplications?.length > 0 && (
        <Popover
          id='mouse-over-popover'
          sx={draftableComparisonViewStyles.popoverContainer}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <Box sx={draftableComparisonViewStyles.cardWrapper}>
            <Card
              data={resultsState?.comparisonApplications?.[comparisonApplicationsIndex]}
              hideApplication={() => {}}
              triggerChatRIA={() => {}}
              setFavoriteInHook={() => {}}
              setInProjectInHook={() => {}}
              setSubscriptionInHook={() => {}}
              hideCardOptions
            />
          </Box>
        </Popover>
      )}
      {/* header titles for iframe pdfs */}
      <Grid container spacing={2} px='24px'>
        <Grid item xs={showFollowUpChatRia ? 9 : 12}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={draftableComparisonViewStyles.headerWrapper}>
            <Stack direction='row' width='50%'>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ ...draftableComparisonViewStyles.titleHeaderWrapper, mr: '30px' }}>
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing={0.5}
                  height={21}
                  width='inherit'>
                  <Tooltip title={parentApplicationNumber ?? ''}>
                    <Typography
                      variant='subtitle2'
                      sx={{
                        ...comparisonClasses.titleTab,
                        ...(fullWidth && { maxWidth: 'inherit' })
                      }}
                      noWrap>
                      {parentApplicationNumber}
                    </Typography>
                  </Tooltip>
                  {parentApplicationSource && (
                    <Tooltip
                      title={parentFlagTooltipTitle}
                      disableInteractive
                      disableFocusListener
                      disableTouchListener>
                      <ParentFlagComponent sx={draftableComparisonViewStyles.flagIcon} />
                    </Tooltip>
                  )}
                  {parentApplicationLabels &&
                    parentApplicationLabels.length > 0 &&
                    getApplicationChipTag(parentApplicationLabels)}
                </Stack>
                {resultsState?.comparisonApplications?.length > 0 && (
                  <Stack>
                    <InfoIconSummary
                      onMouseEnter={e => handlePopoverOpen(e, parentApplicationName ?? '')}
                      onMouseLeave={handlePopoverClose}
                      sx={draftableComparisonViewStyles.infoIcon}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
            {isSwitchOptionAvailable && handleSwapLabels && (
              <Stack direction='row'>
                <Stack direction='row' spacing={1}>
                  <Tooltip title='Click to Switch windows' arrow>
                    <IconButton onClick={handleSwapLabels}>
                      <SwapIcon sx={draftableComparisonViewStyles.swapIcon} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            )}
            <Stack direction='row' width='50%'>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ ...draftableComparisonViewStyles.titleHeaderWrapper, ml: '30px' }}>
                <Stack
                  direction='row'
                  alignItems='center'
                  spacing={0.5}
                  height={21}
                  width='inherit'>
                  <Tooltip title={childApplicationNumber ?? ''}>
                    <Typography
                      variant='subtitle2'
                      sx={{
                        ...comparisonClasses.titleTab,
                        ...(fullWidth && { maxWidth: 'inherit' })
                      }}
                      noWrap>
                      {childApplicationNumber}
                    </Typography>
                  </Tooltip>
                  {childApplicationSource && (
                    <Tooltip
                      title={childFlagTooltipTitle}
                      disableInteractive
                      disableFocusListener
                      disableTouchListener>
                      <ChildFlagComponent sx={draftableComparisonViewStyles.flagIcon} />
                    </Tooltip>
                  )}
                  {childApplicationLabels &&
                    childApplicationLabels.length > 0 &&
                    getApplicationChipTag(childApplicationLabels)}
                </Stack>

                {resultsState?.comparisonApplications?.length > 0 && (
                  <Stack>
                    <InfoIconSummary
                      onMouseEnter={e => handlePopoverOpen(e, childApplicationName ?? '')}
                      onMouseLeave={handlePopoverClose}
                      sx={draftableComparisonViewStyles.infoIcon}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          {/* draftable embedded iframe */}
          <Box sx={draftableComparisonViewStyles.iframeWrapper}>
            <iframe
              style={comparisonClasses.compareIFrame}
              title='compareDocuments'
              src={viewerURL}
              allowFullScreen
            />
          </Box>
        </Grid>
        {showFollowUpChatRia && (
          <Grid item xs={3}>
            <Stack
              spacing={2}
              sx={{
                ...comparisonClasses.container,
                mr: 0,
                height: 'calc(100vh - 156px)',
                maxHeight: 'calc(100vh - 156px)'
              }}>
              <Stack
                spacing={0}
                alignItems='flex-start'
                justifyContent='flex-start'
                height='inherit'>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  spacing={1}
                  height={50}
                  width='100%'
                  p={2}
                  sx={{
                    backgroundColor: 'primary.600',
                    borderRadius: '0px 9px 0px 0px'
                  }}>
                  <Stack direction='row' spacing={1}>
                    <Typography variant='h4' sx={styles.title}>
                      Chat RIA
                    </Typography>
                  </Stack>
                  <IconButton onClick={() => setShowFollowUpChatRia(false)}>
                    <CloseOutlinedIcon
                      sx={{ ...comparisonDialogStyles.closeOutlinedIcon, color: 'white.main' }}
                    />
                  </IconButton>
                </Stack>
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                  sx={styles.reportContainer}>
                  <Tooltip title='Reports'>
                    <Stack
                      display='flex'
                      flexDirection='row'
                      alignItems='center'
                      onClick={event => {
                        event.stopPropagation();
                        handleReportClick();
                      }}>
                      <Badge badgeContent={reportCount} sx={styles.badgeStyle}>
                        <IconButton id='reportdownloadIcon' sx={{ p: 0 }}>
                          <DescriptionIcon
                            sx={{
                              width: '14px',
                              height: '14px',
                              mr: reportCount ? '4px' : '0px'
                            }}
                          />
                        </IconButton>
                      </Badge>
                      <Typography sx={styles.reportText}>Report</Typography>
                    </Stack>
                  </Tooltip>
                </Box>
                <Stack direction='column' width='100%' height='calc(100vh - 245px)'>
                  <Box sx={styles.chatContainer} ref={scrollRef}>
                    <Stack direction='row' mb='20px' width='100%' sx={styles.stackContainer}>
                      <Stack display='flex' flexDirection='row'>
                        <Box height='60px' width='60px'>
                          <BubbleIcon sx={{ height: '60px', width: '60px', color: '#06705F' }} />
                        </Box>
                        <Box>
                          <Stack direction='column' overflow='hidden'>
                            <Typography
                              sx={{
                                fontFamily: 'Mulish',
                                fontSize: '14px',
                                fontWeight: '500'
                              }}>
                              Hello, I&apos;m ChatRIA
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: 'Mulish',
                                fontSize: '14px',
                                fontWeight: '500'
                              }}>
                              Ask me some questions to help compare these two Guidance Documents
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                    {chatFlow?.map((items: any, index: number): any => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={`${index}-chat-bot`}>
                          <ChatBubbles
                            disableChatActions
                            source='us'
                            isDefault={false}
                            items={items}
                            queryLoading={resultLoading}
                            currentSection=''
                            handleAddToReportAction={handleAddToReportAction}
                            handleVerifyAction={() => {}}
                            verifyAnswer={false}
                            handleSetHelpQuery={() => {}}
                            currentApplication=''
                            isARIAMode={false}
                            handleNewSection={() => {}}
                            bubbleIndex={index}
                            brandName=''
                            documentReferencePages={[]}
                            setOpenApplicationOption={() => {}}
                            addedToReportList={addedToReportList}
                            reportLoading={reportLoading}
                            ignoreSection
                            isReport
                          />
                        </React.Fragment>
                      );
                    })}
                  </Box>
                  <Box position='relative' width='100%' px={2}>
                    <Slide direction='up' in={resultLoading} mountOnEnter unmountOnExit>
                      <Stack direction='column' alignItems='center' sx={{}}>
                        <Button
                          onClick={handleCancelCall}
                          variant='contained'
                          sx={{
                            color: 'white.main',
                            textTransform: 'none'
                          }}>
                          <Typography variant='body1'>Cancel this query... </Typography>
                        </Button>
                      </Stack>
                    </Slide>
                    <LinearProgress
                      color='primary'
                      sx={{
                        width: '90%',
                        ml: 'auto',
                        mr: 'auto',
                        mb: 1,
                        visibility: resultLoading ? 'visible' : 'hidden'
                      }}
                    />
                    <Stack
                      direction='row'
                      alignItems='center'
                      sx={{
                        width: '100%',
                        '& > form': {
                          flex: 1,
                          transaction: 'width 0.5s ease-out'
                        },
                        display: 'flex',
                        pb: '20px',
                        alignitems: 'center',
                        justifyContent: 'space-between'
                      }}>
                      <form
                        ref={formRef}
                        onSubmit={async e => {
                          inputRef.current?.focus();
                          e.preventDefault();
                          e.stopPropagation();
                          if (followUpQuery.trim() === '') {
                            return;
                          }

                          const questionId = v4();
                          setLatestChatId({ chatId: questionId, isNew: true });
                          setChatFlow((prevState: any) =>
                            !isEmpty(prevState)
                              ? [
                                  ...prevState,
                                  {
                                    text: followUpQuery,
                                    type: 'user',
                                    id: questionId
                                  }
                                ]
                              : [
                                  {
                                    text: followUpQuery,
                                    type: 'user',
                                    id: questionId
                                  }
                                ]
                          );

                          await handleGetComparisonQueryOutput();
                          inputRef.current?.focus();
                        }}>
                        <FormControl variant='filled' fullWidth>
                          <OutlinedInput
                            multiline
                            maxRows={3}
                            id='query'
                            type='text'
                            value={followUpQuery}
                            required
                            autoFocus
                            onChange={e => setFollowUpQuery(e.target.value)}
                            onKeyDown={e => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                formRef.current?.requestSubmit();
                              }
                            }}
                            inputRef={inputRef}
                            sx={styles.form}
                            endAdornment={
                              <InputAdornment position='end'>
                                <Button
                                  id='input-box-chatRIA'
                                  aria-label='submit query'
                                  type='submit'
                                  sx={{ minWidth: '24px' }}>
                                  <SendIcon sx={{ fontSize: '24px' }} />
                                </Button>
                              </InputAdornment>
                            }
                            placeholder='Type your question here to chat with RIA'
                          />
                        </FormControl>
                      </form>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

DraftableComparisonView.defaultProps = {
  handleSwapLabels: undefined,
  isSwitchOptionAvailable: false,
  parentApplicationName: '',
  childApplicationName: '',
  showFooter: true,
  parentApplicationSource: '',
  childApplicationSource: '',
  parentApplicationLabels: [],
  childApplicationLabels: [],
  fullWidth: true,
  parentPdfLink: '',
  childPdfLink: ''
};

export default React.memo(DraftableComparisonView);
